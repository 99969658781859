html {
    height: 100%;
    overflow-y: scroll;
}
body {
    background-image: url('../images/bg_pattern.png'),url('images/bg1.jpg');
    background-position: 50%;
    background-attachment: fixed;
    background-repeat: repeat;
    background-size: auto,cover;
    height: 100%;
    width: 100%
    background-color: #306fca;
}​

div.cc-window span.cc-message a.cc-link {
    color: rgb(92, 114, 145)!important;
}


/* ==================================================
    small slider
====================================================*/

.small-slider {
    padding:20px 20px 60px 60px!important;
}
/* ==================================================
    reise slider
====================================================*/
#reise-slider {

}

#reise-slider .slider-caption {
    text-align:center;
    min-height:500px;
    padding:40px 80px 80px!important;
}
#reise-slider .slider-caption.no-padding-top {
    padding-top:0px!important;
}

#reise-slider .slider-caption {

}

#reise-slider .slider-caption  i{
    font-size:130px;
}

#reise-slider .slider-caption h2 {
    /*font-size:60px;*/
    margin-bottom:10px;
}

#reise-slider .slider-caption p {
    margin-bottom:10px;
}

.carousel-control.right, .carousel-control.left {
    background-image:none;
}



@media (max-width:767px) {

    #reise-slider .slider-caption  i{
        font-size:80px;
    }

    #reise-slider .slider-caption h2 {
        /*font-size:36px;*/
    }

    #reise-slider .slider-caption {
        text-align:center;
        padding:0 40px 80px;
    }
}
/* ==================================================
    distance slider
====================================================*/

#distance-slider {

}

#distance-slider .slider-caption {
    text-align:center;
    padding:20px 80px 80px!important;
}

#distance-slider .slider-caption {

}

#distance-slider .slider-caption  i{
    font-size:130px;
}

#distance-slider .slider-caption h2 {
    /*font-size:60px;*/
    margin-bottom:10px;
}

#distance-slider .slider-caption p {
    margin-bottom:10px;
}

.carousel-control.right, .carousel-control.left {
    background-image:none;
}



@media (max-width:767px) {

    #distance-slider .slider-caption  i{
        font-size:80px;
    }

    #distance-slider .slider-caption h2 {
        /*font-size:36px;*/
    }

    #distance-slider .slider-caption {
        text-align:center;
        padding:0 40px 80px;
    }
}
/* ==================================================
    video slider
====================================================*/

#video-slider {

}

#video-slider .slider-caption {
    text-align:center;
    padding:20px 80px 80px!important;
}

#video-slider .slider-caption {

}

#video-slider .slider-caption  i{
    font-size:130px;
}

#video-slider .slider-caption h2 {
    /*font-size:60px;*/
    margin-bottom:10px;
}

#video-slider .slider-caption p {
    margin-bottom:10px;
}

.carousel-control.right, .carousel-control.left {
    background-image:none;
}



@media (max-width:767px) {

    #video-slider .slider-caption  i{
        font-size:80px;
    }

    #video-slider .slider-caption h2 {
        /*font-size:36px;*/
    }

    #video-slider .slider-caption {
        text-align:center;
        padding:0 40px 80px;
    }
}


/*
Youtube
*/

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* ==================================================
    buchen
====================================================*/
@media (min-width: 1200px) {
.service-list-margin{
    margin-left: -1.1em!important
} }
@media (max-width: 768px) {
.no-padding-xs{
    padding-left: 0px;
    padding-right:0px;
}
.select-xs-width{
    width:3em;
} }
@media (min-width: 768px) {
.no-padding-sm{
    padding-left: 0px;
    padding-right:0px;
} }
@media (min-width: 768px) {
.padding-sm{
    padding-left: 15px;
    padding-right:15px;
} }
@media (min-width: 768px) {
.padding-xs-list{
    padding-left: 15px;
    padding-right:15px;
} }
@media (min-width: 768px) {
.padding-sm-list{
    padding-left: 25px;
    padding-right:10px;
} }
@media (min-width: 992px) {
.padding-md-list{
    padding-left: 28px;
    padding-right:10px;
} }
@media (min-width: 1200px) {
.padding-lg-list{
    padding-left: 31px;
    padding-right:10px;
} }

.tab-content {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
}

.nav-tabs {
    margin-bottom: 0;
}

.form2colwidth{
    width: 40%;
}

#buchPreis{
    font-size: 4em
}

.ui-state-error{
    background-color: #f2dede!important;
    color: #a94442!important;
}

.dateLegende{
    width:34.016px!important;
    height: 28px!important;
    display: inline;
    text-align:center;
}
.padding-top-40{
    padding-top: 40px;
}


.no-padding-top{
    padding-top: 0px!important;
}
.no-margin-top{
    margin-top: 0px!important;
}
/* ==================================================
    .fewomap
====================================================*/
.fewomap {
    height:35em!important;
}
.reisezielemap {
    height:35em!important;
}

.no-padding {
    padding-left: 0px;
    padding-right:0px;
}

.white-link{
    color:#fff!important;
}
li.active a.white-link{
    color:#000!important;
}


/* ==================================================
    .buchen buttons services
====================================================*/
.bootstrap-select > .btn{
    width: auto!important;
}

/*
Preise
 */

#buchen-preis-modal table td{
    padding: 5px !important
}

.steps p {
    color:black;
    font-weight:lighter;
}

@media (min-width: 992px) {
.steps .list-group{
    margin-right:-32px;
    overflow-y:scroll;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
    border:0px solid;
}

.list-group.panel.panel-default {
    border-radius:0px;

    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

}

.steps .list-group.panel .panel-heading {
    font-weight:bold;
}

.steps .list-group{
    max-height:311px;
    overflow-y:scroll;
}
.steps .list-group-item:first-child{
    border-radius:0px;
}
.steps .list-group-item.active{
    z-index:inherit;
}
.steps video {
    max-height: 311px;
    border-bottom-right-radius:4px;
    border-top-right-radius:4px;
}

.steps  h4{
    text-shadow:inherit;
    font-weight:normal;
}

.steps a.list-group-item.active:after {
    content: "";
    position:absolute;
    border-style: solid;
    right:-1px;
    bottom:0px;
    width: 0;
    height: 0;
    border-width: 0px 0px 35px 16px;
    border-color: transparent transparent hsla(208, 56%, 32%, 1) transparent;
}
.steps a.list-group-item.active:before {
    content: "";
    position:absolute;
    border-style: solid;
    right:-1px;
    top:-1px;
    width: 0;
    height: 0;
    border-width: 0px 16px 35px 0px;
    border-color: transparent hsla(208, 56%, 32%, 1) transparent transparent;
}