.datepick a {
  color: #fff;
  text-decoration: none;
}
.datepick-popup {
  z-index: 1000;
  float:left;
}

.datepick-popup .datepick-month {
  width: 100%!important;
}
@media (max-width: 768px) { 
.datepick-month{
  width:17em;
} }
@media (max-width: 992px) { 
.datepick-month{
  width:17em;
}  }

.datepick-month{
  height:242px;
}
.dayDiv.col-xs-1{
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
}
.datepick-popup .datepick {
  background-color: rgba(66, 66, 66, 0.84)!important;
  width:225px!important;
}
.datepick {
  width:100%!important;
}
.datepick option{
  color:black;
}

.datepick select{
  color:black;
}

.datepick-rtl {
  direction: rtl;
}
.datepick-status {
  clear: both;
  text-align: center;
}
.datepick-clear-fix {
  clear: both;
}

.cellBelegt{
  background-color: #d9534f;
  width:100%;
  height:100%;
  display:block;
}
.cellFrei{
  width:100%;
  height:100%;
  display:block;
}
 

.cellAnreise{
  position: relative;
  width:100%;
  height:100%;
  display:block;
  z-index: 100;
}

.cellAnreise:before {
  content: ""!important;
  display: block;
  position: absolute; /* could also be absolute */
  
  
  top: 0;
  left: 0;
  height: 0px;
  width: 0px;
  z-index: -1;
  border-bottom: 1.8em solid #d9534f;
  border-left: 2.2em solid transparent;
}
.cellAnreiseActive:before {
  content: ""!important;
  display: block;
  position: absolute; /* could also be absolute */
  
  /*z-index: -1; */
  
  top: 0;
  left: 0;
  height: 0px;
  width: 0px;
  border-bottom: 1.8em solid #5cb85c;
  border-left: 2.2em solid transparent;
}


.cellAbreise{
  position: relative;
  width:100%;
  height:100%;
  display:block;
  z-index: 100;
}

@media (min-width: 992px) and (max-width: 1200px) { 
.cellAbreise:after,.cellAbreiseActive:after{
  border-top-width:1.8em !important;
  border-right-width:1.8em!important;
}
.cellAnreise:before,.cellAnreiseActive:before{
  border-bottom-width:1.8em !important;
  border-left-width:1.8em!important;
} }



.cellAbreise:after {
  content: ""!important;
  display: block;
  position: absolute; /* could also be absolute */
  
  
  top: 0;
  left: 0;
  height:0px;
  width: 0px;
  z-index: -1;
  border-top: 1.8em solid #d9534f;
  border-right: 2.2em solid transparent;
}
.cellAbreiseActive:after {
  content: ""!important;
  display: block;
  position: absolute; /* could also be absolute */
  
  
  top: 0;
  left: 0;
  height:0px;
  width: 0px;
  /*z-index: -1; */
  border-top: 1.8em solid #5cb85c;
  border-right: 2.2em solid transparent;
}

.datepick-week{
  padding-top:3px;
}

.ui-state-active.cellFrei{
	color:#FFF!important;
}
.ui-state-active{
  background-color: #5cb85c;
  display: block;
}

.ui-state-disabled{
  color:#AAAAAA!important;
  display: block;
}

.datepick-highlight{
  background-color: #5cb85c;
}